@tailwind base;
@tailwind components;
@tailwind utilities;

.text-1942 {
  font-family: '1942_report1942_report';
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #DACCC2;
}

h1 {
  font-family: '1942_report1942_report';
}